import React, { useState, useRef, useEffect } from 'react';
import ArrowDown from '@splunk/react-icons/ArrowDown';
import ArrowUp from '@splunk/react-icons/ArrowUp';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CellFormatter from './cellFormatter';
import { variables } from '@splunk/themes';

const StyledDiv = styled.div`
    padding-top: 6px;
    padding-left: 4px;
    padding-right: ${(props) => (props.displaySortArrow ? '22px' : '6px')};
    cursor: pointer;
    height: 100%;
`;

const StyledSpan = styled.span`
    float: left;
`;

const StyledDraggableIndicatorContainer = styled.div`
    width: 6px;
    height: 21px;
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 4px;
    margin-top: 4px;
`;

const StyledDraggableIndicator = styled.div`
    visibility: hidden;

    ${StyledDiv}:hover & {
        visibility: visible;
    }

    cursor: move;
    padding: 0.375rem 1.25rem 1.5375em;
    background: radial-gradient(
            circle at 1px 1px,
            ${variables.neutral400},
            ${variables.neutral400} 1px,
            transparent 1px
        )
        0px 0px / 4px 6px;
`;

const StyledResizerContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 6px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledResizer = styled.div`
    width: 2px;
    height: 14px;
    background-color: ${variables.neutral400};
    border-radius: 1px;
`;

export default function SortDropDownMenu({
    sortHandler,
    column,
    sortPreference,
    handleColumnResize,
}) {
    const [dragClass, setDragClass] = useState('');
    const divRef = useRef(null);

    useEffect(() => {
        const observeTarget = divRef.current;
        if (!observeTarget || !column.resizable) {
            return;
        }

        const resizeObserver = new ResizeObserver((entries) => {
            const headerContainer = observeTarget.parentElement;
            const isResizing = headerContainer.classList.contains(
                'react-grid-HeaderCell--resizing'
            );
            if (isResizing || !column.resized) {
                for (const entry of entries) {
                    const { width } = entry.target.getBoundingClientRect();
                    handleColumnResize(column.idx, width);
                }
            }
        });
        resizeObserver.observe(observeTarget);

        return () => {
            resizeObserver.unobserve(observeTarget);
            resizeObserver.disconnect();
        };
    }, [divRef, column, handleColumnResize]);

    const sortDirection = () => {
        if (column.key === sortPreference.column) {
            return !sortPreference.ascending ? (
                <ArrowDown aria-label="Arrow Down" />
            ) : (
                <ArrowUp aria-label="Arrow Up" />
            );
        }
        return;
    };

    function invokeSort() {
        let isAscending;
        if (column.key === sortPreference.column) {
            isAscending = !sortPreference.ascending;
        } else {
            isAscending = !column.isPlot;
        }
        sortHandler(column.key, isAscending ? 'ASC' : 'DESC');
    }

    function handleDragOver() {
        if (!column.isPlot) {
            setDragClass('rdg-drag-target');
        }
    }

    function handleDragLeave() {
        setDragClass('');
    }

    return (
        <StyledDiv
            className={dragClass}
            onClick={() => invokeSort()}
            onDragOver={() => handleDragOver()}
            onDragLeave={() => handleDragLeave()}
            onDrop={() => handleDragLeave()}
            displaySortArrow={sortDirection() !== undefined}
            ref={divRef}
        >
            <StyledSpan>{sortDirection()}</StyledSpan>
            <CellFormatter value={column.name} />
            {column.draggable && (
                <StyledDraggableIndicatorContainer>
                    <StyledDraggableIndicator></StyledDraggableIndicator>
                </StyledDraggableIndicatorContainer>
            )}
            {column.resizable && (
                <StyledResizerContainer>
                    <StyledResizer />
                </StyledResizerContainer>
            )}
        </StyledDiv>
    );
}

SortDropDownMenu.propTypes = {
    sortHandler: PropTypes.func,
    column: PropTypes.object,
    sortPreference: PropTypes.object,
    handleColumnResize: PropTypes.func,
};
